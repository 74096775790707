import React, { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ALL_ROUTES from "./util/Route";
import { apiGenerator, isLogin, isUserTaskIdCheck } from "./util/functions";
import useHttp from "./hooks/use-http";
import CONSTANTS from "./util/constant/CONSTANTS";

function App() {
  
  const [admindetails, setAdmindetails] = useState(null);
  const [userTaskData, setuserTaskData] = useState(null);
  const [taskData, setTaskData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [userData, setUserData] = useState({});
  const [unAuth, setUnAuth] = useState(false);
  
  // const { userTaskId, userId } = useParams();

  const api = useHttp();

  const getClientAPIHandler = async () => {
    const userId = localStorage.getItem("userId");
    if (!userId) return;
    let errTxt = null;
    await api.sendRequest(
      apiGenerator(CONSTANTS.API.getClient, 
        {}, 
        `?userId=${userId}`),
      (res) => {
        setUserData(res?.data);
        // if(window.location.pathname == "/error")
      },
      null,
      null,
      (errMsg) => {
        setUnAuth(true);
        // window.location.pathname = "/error"
      }
    );
    if (!isLogin()) return;
    const GET_MY_PROFILE_API = { ...CONSTANTS.API.getMe };
    await api.sendRequest(GET_MY_PROFILE_API, (res) => {
      setAdmindetails(res?.data);
      // localStorage.setItem("taskId", res?.data?.taskId);
    } ,null,
    null,
    (errMsg) => {
      console.log(errMsg)
      
      // window.location.pathname = "/error"
      errTxt = errMsg
    });
    if(errTxt != null) return
      
    const taskId = localStorage.getItem("taskId");
    if (taskId) {
      await api.sendRequest(
        apiGenerator(CONSTANTS.API.task.getOne, {
          dataId: taskId, //taskid //
        }),
        (res) => {
          setTaskData(res?.data);
          // setStatus(res?.data?.userTasks?.[0]?.status ?? "")
        }
      );
    }
  };

  useEffect(() => {
    getClientAPIHandler();

    // if (userTaskId && userId) {
    //   api.sendRequest(
    //     apiGenerator(CONSTANTS.API.getClient, {}, `?userTaskId=${userTaskId}&userId=${userId}`), (res) => {
    //       localStorage.setItem("taskId", res?.data?.taskId);;
    //       setUserData(res?.data?.user)
    //     }, null, null, () => setUnAuth(true)
    //   )
    // }

    // if (!isLogin()) return;
    // const GET_MY_PROFILE_API = { ...CONSTANTS.API.getMe };
    // api.sendRequest(GET_MY_PROFILE_API, (res) => {
    //   setAdmindetails(res?.data);
    //   // localStorage.setItem("taskId", res?.data?.taskId);
    // });
    // const taskId = localStorage.getItem("taskId");
    // if (taskId) {
    //   api.sendRequest(
    //     apiGenerator(CONSTANTS.API.task.getOne, {
    //       dataId: taskId, //taskid //
    //     }),
    //     (res) => {
    //       setTaskData(res?.data);
    //       // setStatus(res?.data?.userTasks?.[0]?.status ?? "")
    //     }
    //   );
    // }

    // const userTaskId = localStorage.getItem("userTaskId")
    // if (userTaskId) {
    //   api.sendRequest(apiGenerator(CONSTANTS.API.userTask.getOne, {
    //     dataId: +userTaskId
    //   }), (res) => {
    //     setuserTaskData(res?.data)
    //   });
    // }
  }, [isLogin, refresh]);

  const router = createBrowserRouter(
    ALL_ROUTES({
      admindetails,
      // userTaskData,
      userData,
      taskData,
      setRefresh,
      unAuth,
    })
  );
  return <RouterProvider router={router} />;
}

export default App;
