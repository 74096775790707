import {
  Avatar,
  Button,
  Card,
  Image,
  InputNumber,
  Popconfirm,
  Rate,
  Select,
  Switch,
} from "antd";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  BellOutlined,
} from "@ant-design/icons";
import logo from "../../asset/logos/icon.svg";
import stars from "../../asset/icons/star.svg";
import emptyStars from "../../asset/icons/emptyStar.svg";

const alphanumericSort = (a, b, value) => {
  if (isNaN(parseInt(a[value]))) {
    return a[value]?.localeCompare(b[value]);
  }
  return a[value] - b[value];
};

const RenderActionButtons = (value) => {
  let ButtonsArray = [];
  if (value?.Delete) {
    ButtonsArray.push(RenderDeleteButton(value.Delete));
  }
  if (value?.Edit) {
    ButtonsArray.push(RenderEditButton(value.Edit));
  }
  if (value?.View) {
    ButtonsArray.push(RenderViewButton(value.View));
  }
  if (value?.Download) {
    ButtonsArray.push(RenderDownloadButton(value.Download));
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        // paddingRight: "5px",
      }}
    >
      {ButtonsArray?.map((el, i) => (
        <div key={i}>{el}</div>
      ))}
    </div>
  );
};

//render banner image

const RenderBannerImages = (value) => {
  let imageSrc = value;
  if (!imageSrc) {
    imageSrc = logo;
  }
  return (
    <Card
      className="h-[86px] w-[172px] rounded-lg overflow-hidden shadow-lg border border-gray-300"
      bodyStyle={{ padding: 0, display: "flex", justifyContent: "center" }}
    >
      <Image
        height={86}
        width={172}
        style={{ aspectRatio: "172/86", objectFit: "cover", padding: 8 }}
        src={imageSrc}
        alt={value}
      />
    </Card>
  );
};

const RenderNumberInput = (value) => {
  const { data, onBlur } = value;

  return (
    <InputNumber
      className="w-20"
      value={data}
      controls={false}
      onBlur={(e) => onBlur(e.target.value)}
      placeholder="Rank"
    />
  );
};

// Delete Button
const RenderDeleteButton = (value) => {
  const { id, onClick } = value;
  return (
    <Popconfirm title="Sure to Delete?" onConfirm={() => onClick(id)}>
      <Button type="primary">
        {value?.name ? value?.name : <DeleteOutlined />}
      </Button>
      {/* <Button type="primary">{value?.name}</Button> */}
    </Popconfirm>
  );
};

// Delete button without confimation model
const RenderDeleteWithoutConfirmation = (value) => {
  return (
    <Popconfirm
      title="Sure to Delete?"
      open={false}
      onOpenChange={() => value?.onClick(value.key)}
      onConfirm={() => value?.onClick(value.key)}
    >
      {value?.name ? (
        value?.name
      ) : (
        <DeleteOutlined style={{ color: "black" }} />
      )}
    </Popconfirm>
  );
};
// Download Button
const RenderDownloadButton = (value) => {
  return (
    <DownloadOutlined
      onClick={() => {
        window.open(value, "Download");
      }}
    />
  );
};

// Edit Button
export const RenderEditButton = (value) => {
  const { id, onClick, disabled = false } = value;
  return (
    <Button
      type="primary"
      disabled={disabled}
      onClick={() => {
        onClick(id);
      }}
    >
      <EditOutlined />
    </Button>
  );
};

// Render View Button
const RenderViewButton = (value) => {
  // console.log(value);
  const { id = null, onClick = () => {} } = value;
  return (
    <Button
      onClick={() => {
        // console.log('click');
        onClick(id);
      }}
      type="primary"
    >
      <EyeOutlined />
    </Button>
  );
};

// Render View Button
const RenderNotificationButton = (value) => {
  // console.log(value);
  const { id = null, onClick = () => {} } = value;
  return (
    <Button
      onClick={() => {
        onClick(id);
      }}
      type="primary"
    >
      <BellOutlined />
    </Button>
  );
};

// Render Images
const UserImageRanders = (value) => {
  return (
    <div>
      {value?.image ? (
        <Image
          width={50}
          height={50}
          className="rounded-full object-cover"
          src={value?.image}
          alt={value}
        />
      ) : (
        <Avatar className={`flex items-center w-12 h-12 text-xl bg-gray-600`}>
          {value?.name?.charAt(0).toUpperCase()}
        </Avatar>
      )}
    </div>
  );
};

// Render Images
const ImageRanders = (value) => {
  // console.log(value);
  let imageSrc = value;
  if (!imageSrc) {
    imageSrc = logo;
  }
  return (
    <div>
      <Image
        width={50}
        height={50}
        className="rounded-full object-cover"
        src={imageSrc}
        alt={value}
      />
    </div>
  );
};

const LinkRenderer = (value) => {
  return value ? (
    <a
      href={value}
      target="_blank"
      rel="noopener noreferrer"
      className="text-primary"
    >
      {value}
    </a>
  ) : (
    <p className="text-red-400">Link not found</p>
  );
};

// Toggle Button
export const RenderToggleButton = (value) => {
  // console.log(value);
  const { checked, id, onClick, size = "default", isDisable = false } = value;
  return (
    <div>
      <Switch
        size={size}
        onChange={() => {
          onClick(id, checked);
        }}
        checked={!checked}
        disabled={isDisable}
      />
    </div>
  );
};

// QR Button
const RenderQrButton = (value = {}) => {
  const { id, onClick } = value;
  return (
    <Button
      type="primary"
      onClick={() => {
        onClick(id);
      }}
    >
      Generate QR
    </Button>
  );
};

// Ratings
const RenderRating = (value) => {
  // console.log(value);
  const { ratings } = value;
  return (
    <div>
      {/* {ratings}&nbsp;< StarFilled /> */}

      <Rate
        disabled
        allowHalf
        value={ratings}
        character={({ index }) => {
          if (index < ratings) {
            return <img src={stars} />;
          } else {
            return <img src={emptyStars} />;
          }
        }}
      />
    </div>
  );
};

const RenderButtonWithIcon = (value) => {
  // console.log(value);
  const { id = null, onClick = () => {}, icon = null } = value;
  return (
    <Button
      onClick={() => {
        onClick(id);
      }}
      type="primary"
    >
      {icon}
    </Button>
  );
};
export const loginRoot = "/";
export const appRoot = "/task";

const CONSTANTS = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  GETMe: null,
  TABLE: {
    ROLE_MANAGEMENT: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
      },

      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },

      {
        title: "Email",
        dataIndex: "email",
        sorter: (a, b) => alphanumericSort(a, b, "email"),
        width: 180,
      },
      {
        title: "Role",
        dataIndex: "role",
        width: 100,
      },

      {
        title: "Edit",
        dataIndex: "editTableRow",
        render: RenderEditButton,
        width: 50,
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        render: RenderDeleteButton,
        width: 70,
      },
    ],
  },
  FORM_FIELD: {
    LOGIN_PAGE_MODAL: [
      {
        no: 1,
        // Label: "User Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "User name",
        required: true,
        width: 24,
      },
      {
        no: 1,
        // Label: "Password",
        name: "password",
        width: 24,
        id: "password",
        type: "password",
        placeholder: "Password",
        required: true,
      },
    ],
    REGISTRATION_PAGE_MODAL: [
      {
        no: 0,
        // Label: "Name",
        name: "image",
        id: "image",
        position: "end",
        type: "profile-image-upload",
        // placeholder: "As per your legal ID",
        required: false,
        width: 12,
        // labelwidth: 24,
      },
      {
        no: 1,
        Label: "Select Gender",
        name: "gender",
        id: "gender",
        type: "radio",
        placeholder: "Enter a Gender",
        direction: "horizontal",
        option: [
          {
            id: 1,
            Label: "Male",
            value: "male",
          },
          {
            id: 2,
            Label: "Female",
            value: "female",
          },
          {
            id: 3,
            Label: "Other",
            value: "other",
          },
        ],
        required: true,
        width: 12,
        labelwidth: 24,
      },
      {
        no: 33,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "As per your legal ID",
        required: true,
        width: 12,
        labelwidth: 24,
      },
      {
        no: 2,
        Label: "Mobile No.",
        name: "mobile",
        id: "mobile",
        type: "mobile",
        prefix: "+91",
        placeholder: "Enter a Mobile No.",
        required: true,
        width: 12,
        labelwidth: 24,
        disabled: true,
        // extraLabel: "+91",
      },
      {
        no: 3,
        Label: "Date of Birth",
        name: "DOB",
        id: "DOB",
        type: "date",
        placeholder: "Enter a Date of Birth",
        format: "DD-MM-YYYY",
        required: true,
        width: 12,
        labelwidth: 24,
      },
      {
        no: 4,
        Label: "Email ID",
        name: "email",
        id: "email",
        type: "email",
        placeholder: "Enter a Email",
        required: false,
        width: 12,
        labelwidth: 24,
      },
      {
        no: 5,
        Label: "Address",
        name: "addressLine1",
        id: "addressLine1",
        type: "text",
        placeholder: "Address Line 1",
        required: false,
        width: 24,
        labelwidth: 24,
      },
      {
        no: 6,
        Label: "",
        name: "addressLine2",
        id: "addressLine2",
        type: "text",
        placeholder: "Address Line 2",
        required: false,
        width: 16,
        // labelwidth: 24,
      },
      {
        no: 7,
        // Label: "PinCode",
        name: "pinCode",
        id: "pinCode",
        type: "number",
        placeholder: "Enter a PinCode",
        rule: {
          validator: (_, value) => {
            if (!value || /^\d{6}$/.test(value)) {
              return Promise.resolve();
            }
            return Promise.reject("Please enter a 6-digit pin code!");
          },
        },
        required: false,
        width: 8,
      },
      {
        no: 8,
        // Label: "City",
        name: "city",
        id: "city",
        type: "text",
        placeholder: "Enter a City",
        required: false,
        width: 8,
      },
      {
        no: 9,
        // Label: "State",
        name: "state",
        id: "state",
        type: "text",
        placeholder: "Enter a State",
        required: false,
        width: 8,
      },
      {
        no: 10,
        // Label: "Country",
        name: "country",
        id: "country",
        type: "text",
        placeholder: "Enter a Country",
        required: false,
        width: 8,
      },
      {
        no: 11,
        // Label: "Landmark",
        // name: "isAffiliateUser",
        id: "isAffiliateUser",
        type: "text",
      },
    ],
    REGISTRATION_PAGE_EDIT_MODAL: [
      {
        no: 0,
        // Label: "Name",
        name: "image",
        id: "image",
        position: "end",
        type: "profile-image-upload",
        // placeholder: "As per your legal ID",
        required: false,
        width: 12,
        // labelwidth: 24,
      },
      {
        no: 1,
        Label: "Select Gender",
        name: "gender",
        id: "gender",
        type: "radio",
        placeholder: "Enter a Gender",
        direction: "horizontal",
        option: [
          {
            id: 1,
            Label: "Male",
            value: "male",
          },
          {
            id: 2,
            Label: "Female",
            value: "female",
          },
          {
            id: 3,
            Label: "Other",
            value: "other",
          },
        ],
        required: true,
        width: 12,
        labelwidth: 24,
      },
      {
        no: 33,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "As per your legal ID",
        required: true,
        width: 12,
        labelwidth: 24,
      },
      {
        no: 2,
        Label: "Mobile No.",
        name: "mobile",
        id: "mobile",
        type: "mobile",
        prefix: "+91",
        placeholder: "Enter a Mobile No.",
        required: true,
        width: 12,
        labelwidth: 24,
        // extraLabel: "+91",
      },
      {
        no: 3,
        Label: "Date of Birth",
        name: "DOB",
        id: "DOB",
        type: "date",
        placeholder: "Enter a Date of Birth",
        format: "DD-MM-YYYY",
        required: true,
        width: 12,
        labelwidth: 24,
      },
      {
        no: 4,
        Label: "Email ID",
        name: "email",
        id: "email",
        type: "email",
        placeholder: "Enter a Email",
        required: false,
        width: 12,
        labelwidth: 24,
      },
      {
        no: 5,
        Label: "Address",
        name: "addressLine1",
        id: "addressLine1",
        type: "text",
        placeholder: "Address Line 1",
        required: false,
        width: 24,
        labelwidth: 24,
      },
      {
        no: 6,
        Label: "",
        name: "addressLine2",
        id: "addressLine2",
        type: "text",
        placeholder: "Address Line 2",
        required: false,
        width: 16,
        // labelwidth: 24,
      },
      {
        no: 7,
        Label: "PinCode",
        name: "pinCode",
        id: "pinCode",
        rule: {
          validator: (_, value) => {
            if (!value || /^\d{6}$/.test(value)) {
              return Promise.resolve();
            }
            return Promise.reject("Please enter a 6-digit pin code!");
          },
        },
        type: "number",
        placeholder: "Enter a PinCode",
        required: false,
        width: 8,
      },
      {
        no: 8,
        // Label: "City",
        name: "city",
        id: "city",
        type: "text",
        placeholder: "Enter a City",
        required: false,
        width: 8,
      },
      {
        no: 9,
        // Label: "State",
        name: "state",
        id: "state",
        type: "text",
        placeholder: "Enter a State",
        required: false,
        width: 8,
      },
      {
        no: 10,
        // Label: "Country",
        name: "country",
        id: "country",
        type: "text",
        placeholder: "Enter a Country",
        required: false,
        width: 8,
      },
      {
        no: 11,
        // Label: "Landmark",
        // name: "isAffiliateUser",
        id: "isAffiliateUser",
        type: "text",
      },
    ],
  },

  API: {
    login: {
      type: "POST",
      endpoint: "/admin/login",
    },
    signUp: {
      type: "POST",
      endpoint: "/auth/signup",
    },
    editProfile: {
      type: "PATCH",
      endpoint: "/auth/updateMe",
    },
    getClient: {
      type: "GET",
      endpoint: "/userTask/validate-user",
    },

    getMe: {
      type: "GET",
      endpoint: "/auth/getMe",
    },
    sendOTP: {
      type: "POST",
      endpoint: "/auth/sendOTP",
    },
    verifyOTP: {
      type: "POST",
      endpoint: "/auth/verifyOTP",
    },

    dashbaord: {},
    task: {
      getOne: {
        type: "GET",
        endpoint: "/task/:dataId",
      },
    },
    userTask: {
      add: {
        type: "POST",
        endpoint: "/userTask",
      },
      getOne: {
        type: "GET",
        endpoint: "/userTask/:dataId",
      },
      update: {
        type: "PATCH",
        endpoint: "/userTask/:dataId",
      },
      updateWithdraw: {
        type: "PATCH",
        endpoint: "/userTask/withdraw-request/:dataId",
      },
    },
  },

  TAB_MENU: {
    USER: [
      {
        no: 1,
        Label: "Task",
        name: "task",
        id: "task",
      },
      {
        no: 2,
        Label: "Transactions",
        name: "transaction",
        id: "transaction",
      },
    ],
    TASK: [
      {
        no: 1,
        Label: "All",
        name: "all",
        id: "all",
      },
      {
        no: 2,
        Label: "Reject",
        name: "reject",
        id: "reject",
      },
      {
        no: 3,
        Label: "Rework",
        name: "rework",
        id: "rework",
      },
      {
        no: 4,
        Label: "Accept",
        name: "accept",
        id: "accept",
      },
    ],
  },
};
export default CONSTANTS;
