export const getAuthToken = () => {
  return localStorage.getItem("token");
};

export const setAuthDetails = (accessToken) => {
  localStorage.setItem("token", accessToken);
};

export const deleteAuthDetails = () => {
  const keysToKeep = ["userId", "taskId", 'mobileNo'];
  Object.keys(localStorage).forEach((key) => {
    if (!keysToKeep.includes(key)) {
      localStorage.removeItem(key);
    }
  });
};
