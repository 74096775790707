/* eslint-disable react-hooks/exhaustive-deps */
// import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
// import {
//   Avatar,
//   Button,
//   Col,
//   Drawer,
//   Dropdown,
//   Form,
//   Image,
//   Layout,
//   Menu,
//   Modal,
//   Row,
// } from "antd";
// import { useEffect, useState } from "react";
// import { appRoot, loginRoot } from "../../util/constant/CONSTANTS";
// import Profile from "../../asset/image/dummy-avatar.jpg";
// import { getAuthToken } from "../../util/API/authStorage";
// import { deleteAuthDetails } from "../../util/API/authStorage";
// import logo from "../../asset/icons/Headerlogo.svg";
// import { CgProfile } from "react-icons/cg";
// import { MdOutlineSupportAgent } from "react-icons/md";
// import { TbLogout } from "react-icons/tb";
// import RegistrationCard from "../modalCard/registrationCard";
// import {
//   isLogin,
//   isMobile,
//   isUserTaskIdCheck,
//   requestLocationPermission,
// } from "../../util/functions";
// import dummyAvatar from "../../asset/image/dummy-avatar.jpg";

// const { Header, Content, Sider } = Layout;

// const AppLayout = ({ admindetails, setRefresh, userData }) => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [editId, setEditId] = useState(null);
//   const [visible, setVisible] = useState(false);
//   const { userId, userTaskId } = useParams();

//   // if()
//   const [form] = Form.useForm();

//   const pathMatch = location?.pathname?.split("/");
//   const checkDataFind = async () => {
//     const storedUserTaskId = localStorage.getItem("userTaskId");
//     const storedUserId = localStorage.getItem("userId");
//     debugger
//     if (location?.pathname?.includes("task") && pathMatch?.length > 2) {
//       console.log("log");
//       const userTaskIds = location?.pathname?.split("/")?.[2];
//       const userIds = location?.pathname?.split("/")?.[3];

//       if ((!storedUserTaskId && !storedUserId) || !isLogin()) {
//         console.log("kjh");
//         await localStorage.setItem("userTaskId", userTaskIds);
//         await localStorage.setItem("userId", userIds);
//         return await navigate(loginRoot);
//       }
//       // return storeIds(userTaskId, userId, navigate);
//     }

//     const isUserTaskIdSame = await isUserTaskIdCheck(handleLogout);
//     console.log(isUserTaskIdSame, "djfhsdjgfbjdyhfjdgfjdg");
//     if (storedUserTaskId && storedUserId && isLogin()) {
//       const genPath = `${appRoot}/${isUserTaskIdSame}/${storedUserId}`;
//       console.log(isUserTaskIdSame, storedUserId, "djfhjdghfjdfjd");

//       if (location?.pathname !== genPath && !userId && !userTaskId)
//         return await navigate(genPath);
//       if (isUserTaskIdSame == storedUserTaskId) return;

//       // setRefresh(pr => !pr);
//       return await window.location.assign(genPath);
//     }

//     // if (userId !== storedUserId || isUserTaskIdSame != storedUserTaskId) {
//     //   navigate(`${appRoot}/${isUserTaskIdSame}/${storedUserId}`)
//     // }

//   }
//   useEffect(() => {
//     requestLocationPermission();
//     console.log("applayout");
//     // const storedUserTaskId = localStorage.getItem("userTaskId");
//     // const storedUserId = localStorage.getItem("userId");
//     // if (location?.pathname?.includes("task") && pathMatch?.length > 2) {
//     //   console.log("log");
//     //   const userTaskIds = location?.pathname?.split("/")?.[2];
//     //   const userIds = location?.pathname?.split("/")?.[3];
//     //   if ((!storedUserTaskId && !storedUserId) || !isLogin()) {
//     //     console.log("kjh");
//     //     localStorage.setItem("userTaskId", userTaskIds);
//     //     localStorage.setItem("userId", userIds);
//     //     return navigate(loginRoot);
//     //   }
//     //   // return storeIds(userTaskId, userId, navigate);
//     // }
//     checkDataFind();
//     if (!(getAuthToken() !== undefined && getAuthToken() !== null)) {
//       navigate(loginRoot);
//       return;
//     }
//     console.log("first")

//     // const isUserTaskIdSame = isUserTaskIdCheck(handleLogout);
//     // console.log(isUserTaskIdSame, "djfhsdjgfbjdyhfjdgfjdg");
//     // if (storedUserTaskId && storedUserId && isLogin()) {
//     //   const genPath = `${appRoot}/${isUserTaskIdSame}/${storedUserId}`;
//     //   console.log(isUserTaskIdSame, storedUserId, "djfhjdghfjdfjd");

//     //   if (location?.pathname !== genPath && !userId && !userTaskId)
//     //     return navigate(genPath);
//     //   if (isUserTaskIdSame == storedUserTaskId) return;

//     //   // setRefresh(pr => !pr);
//     //   return window.location.assign(genPath);
//     // }

//     // else
//     // if (!location?.pathname?.includes("task") && pathMatch?.length === 3) {
//     //   const userTaskId = location?.pathname?.split("/")?.[1]
//     //   const userId = location?.pathname?.split("/")?.[2]

//     //   storeIds(userTaskId, userId);
//     // }
//   }, []);
//   useEffect(() => {
//     // const userId = localStorage.getItem("userId");
//     // const userTaskId = localStorage.getItem("userTaskId");
//     // if (!userTaskId || !userId) return navigate("/error")

//     const userRole = localStorage.getItem("userType");
//     const storedUserTaskId = localStorage.getItem("userTaskId");
//     const storedUserId = localStorage.getItem("userId");
//     if (userRole && userRole !== "old") return navigate("/registration");

//     //NEW==================================
//     const isUserTaskIdSame = isUserTaskIdCheck();
//     console.log(isUserTaskIdSame, "djfhsdjgfbjdyhfjdgfjdg");
//     if (storedUserTaskId && storedUserId && isLogin()) {
//       const genPath = `${appRoot}/${isUserTaskIdSame}/${storedUserId}`;
//       if (location?.pathname === genPath) return;
//       // debugger
//       // setRefresh(pr => !pr);
//       // return window.location.assign(genPath);
//     }

//     // if (storedUserTaskId && storedUserId && isLogin()) {
//     //   const genPath = `${appRoot}/${storedUserTaskId}/${storedUserId}`;
//     //   if (location?.pathname === genPath) return;
//     //   return navigate(genPath);
//     // }
//   }, [admindetails]);

//   const onClose = () => {
//     setVisible(false);
//   };
//   const handleLogout = () => {
//     deleteAuthDetails();

//     // navigate("/");
//     window.location.assign("/");
//   };

//   const items = [
//     {
//       key: "1",
//       label: "Profile",
//       icon: <CgProfile size={19} />, // Profile icon
//       onClick: () => setEditId(admindetails?.id),
//     },
//     {
//       key: "2",
//       label: "Help",
//       icon: <MdOutlineSupportAgent size={19} />, // Help icon
//       onClick: () =>
//         window.open(
//           `https://api.whatsapp.com/send?phone=${userData?.mobile}`,
//           "_blank"
//         ),
//     },
//     {
//       key: "3",
//       label: "Logout",
//       icon: <TbLogout style={{ color: "red" }} size={19} />, // Logout icon with red color
//       onClick: handleLogout, // Call handleLogout when clicked
//     },
//   ];

//   return (
//     <>
//       <Layout
//         hasSider={true}
//         style={{
//           minHeight: "100vh",
//           // userSelect: "none",
//         }}
//       >
//         <Layout className="site-layout">
//           <Header
//             className="flex justify-between flex-col !bg-white h-[70px] py-1 px-0"
//             style={
//               {
//                 // padding: "5px 0px",
//                 // height: "70px",
//               }
//             }
//           >
//             <Row
//               align="middle"
//               justify="space-between"
//               style={{ padding: "7px 35px" }}
//             >
//               <Col span={16} className="flex items-center flex gap-2">
//                 <Avatar
//                   src={userData?.image ?? dummyAvatar}
//                   size={50}
//                   style={{ border: "1px solid #dce0e6" }}
//                 />
//                 <div className="">
//                   {" "}
//                   <p className="text-primary text-[14px] xs:text-[13px] font-semibold leading-6">
//                     {"Team Leader"}
//                   </p>
//                   <p className="text-[16px] xs:text-[12px] leading-6 ">
//                     {userData?.name}
//                   </p>
//                 </div>
//               </Col>
//               <Col className="flex justify-end" span={8}>
//                 <Dropdown
//                   menu={{ items }}
//                   placement="bottomRight"
//                   arrow
//                   trigger={["click"]}
//                 >
//                   <Avatar
//                     src={admindetails?.image ?? dummyAvatar}
//                     size={50}
//                     style={{
//                       border: "1px solid #dce0e6",
//                       cursor: "pointer",
//                     }}
//                   />
//                 </Dropdown>
//               </Col>
//             </Row>
//           </Header>
//           <Content
//             style={{
//               margin: "0 16px",
//             }}
//           >
//             <Outlet />

//             {/* {getAuthToken() !== undefined && getAuthToken() !== null && (
//               <Outlet />
//             )} */}
//           </Content>
//         </Layout>
//       </Layout>
//       {/* <div> */}
//       <Drawer
//         placement="right"
//         closable={false}
//         onClose={onClose}
//         open={visible}
//       >
//         <div className="flex-x center text-center profile-drawer">
//           <div>
//             <Avatar
//               size={100}
//               style={{ color: "#fffff", backgroundColor: "#000000" }}
//               className="mt-10"
//               src={Profile}
//             >
//               <div style={{ fontWeight: "400", fontSize: "2rem" }}>
//                 {/* {UserData.fullname.split(" ")[0].charAt(0).toUpperCase()} */}
//               </div>
//             </Avatar>
//             <div className="mt-5 text-2xl font-medium">
//               {admindetails?.name || "Website Admin"}
//             </div>
//             {/* <div className="an-24 regular-text mt20">{UserData?.siteName}</div> */}
//             <div className="text-slate-500">
//               {admindetails?.email || "admin@test.com"}
//             </div>
//             <Button
//               danger
//               htmlType="submit"
//               className="mt-5 w-40 h-10"
//               onClick={handleLogout}
//             >
//               Logout
//             </Button>
//           </div>
//         </div>
//         <hr className="my30" style={{ background: "#E4E8F0" }} />
//       </Drawer>
//       <Modal
//         open={editId}
//         width={!isMobile() && 600}
//         okText="Submit"
//         footer={false}
//         cancelButtonProps={{ style: { display: "none" } }}
//         className="form-modal-title"
//         onCancel={() => {
//           setEditId(null);
//           form?.resetFields();
//         }}
//       >
//         <RegistrationCard
//           form={form}
//           admindetails={admindetails}
//           editId={editId}
//           setEditId={setEditId}
//           setRefresh={setRefresh}
//         />
//       </Modal>
//     </>
//   );
// };

// export default AppLayout;

import { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams, Outlet } from "react-router-dom";
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Form,
  Layout,
  Row,
  Drawer,
  Modal,
} from "antd";
import { appRoot, loginRoot } from "../../util/constant/CONSTANTS";
import { getAuthToken, deleteAuthDetails } from "../../util/API/authStorage";
import {
  isLogin,
  isUserTaskIdCheck,
  requestLocationPermission,
  isMobile,
} from "../../util/functions";
import Profile from "../../asset/image/dummy-avatar.jpg";
import RegistrationCard from "../modalCard/registrationCard";
import dummyAvatar from "../../asset/image/dummy-avatar.jpg";
import { CgProfile } from "react-icons/cg";
import { MdOutlineSupportAgent } from "react-icons/md";
import { TbLogout } from "react-icons/tb";

const { Header, Content } = Layout;

const AppLayout = ({ admindetails, setRefresh, userData, unAuth }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [editId, setEditId] = useState(null);
  const [visible, setVisible] = useState(false);
  const { userId, taskId, mobileNo } = useParams();

  const [form] = Form.useForm();

  const checkDataFind = async () => {
    const storedTaskId = localStorage.getItem("taskId");
    const storedUserId = localStorage.getItem("userId");
    const storedmobileNo = localStorage.getItem("mobileNo");
    // const pathMatch = location?.pathname?.split("/");

    try {
      // Ensure location-based checks are handled sequentially
      if (userId && taskId) {
        // const taskIds = pathMatch[2];
        // const userIds = pathMatch[3];

        // If not logged in, store the userTaskId and userId, then navigate to login
        if ((!storedTaskId && !storedUserId && !storedmobileNo) || !isLogin()) {
          await localStorage.setItem("taskId", taskId);
          await localStorage.setItem("userId", userId);
          await localStorage.setItem("mobileNo", mobileNo);
          await setRefresh((pr) => !pr);
          return navigate(loginRoot);
        }
      }

      const isUserTaskIdSame = await isUserTaskIdCheck(handleLogout);
      if (storedTaskId && storedUserId && isLogin()) {
        const genPath = `${appRoot}/${isUserTaskIdSame?.taskId}/${isUserTaskIdSame?.userId}/${storedmobileNo}`;

        if (location?.pathname !== genPath && !userId && !taskId) {
          return navigate(genPath);
        }

        if (!isUserTaskIdSame?.reload) return;

        // Force a page reload to assign correct path if necessary
        return window.location.assign(genPath);
      }
    } catch (error) {
      console.error("Error in checkDataFind:", error);
      navigate("/error");
    }
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        // Request location permissions
        await requestLocationPermission();
        await checkDataFind();

        // Check if the token exists; if not, navigate to login
        if (!(getAuthToken() !== undefined && getAuthToken() !== null)) {
          return navigate(loginRoot);
        }
      } catch (error) {
        console.error("Error in initialization:", error);
      }
    };

    initialize();
  }, []);

  useEffect(() => {
    const validateUserRole = async () => {
      try {
        const userRole = localStorage.getItem("userType");
        const storedTaskId = localStorage.getItem("taskId");
        const storedUserId = localStorage.getItem("userId");

        if (userRole && userRole !== "old") return navigate("/registration");

        // const isUserTaskIdSame = await isUserTaskIdCheck();

        if (storedTaskId && storedUserId && isLogin()) {
          const genPath = `${appRoot}/${storedTaskId}/${storedUserId}`;
          if (location?.pathname === genPath) return;
        }
      } catch (error) {
        console.error("Error validating user role:", error);
      }
    };

    validateUserRole();
  }, [admindetails]);

  useEffect(() => {
    if (unAuth) return navigate("/error");
  }, [unAuth]);

  const handleLogout = () => {
    deleteAuthDetails();
    window.location.assign("/");
  };

  const items = [
    {
      key: "1",
      label: "Profile",
      icon: <CgProfile size={19} />,
      onClick: () => setEditId(admindetails?.id),
    },
    {
      key: "2",
      label: "Help",
      icon: <MdOutlineSupportAgent size={19} />,
      onClick: () =>
        window.open(
          `https://api.whatsapp.com/send?phone=${userData?.mobile}`,
          "_blank"
        ),
    },
    {
      key: "3",
      label: "Logout",
      icon: <TbLogout style={{ color: "red" }} size={19} />,
      onClick: handleLogout,
    },
  ];

  return (
    <>
      <Layout hasSider={true} style={{ minHeight: "100vh" }}>
        <Layout className="site-layout">
          <Header className="flex justify-between flex-col !bg-white h-[70px] py-1 px-0">
            <Row
              align="middle"
              justify="space-between"
              style={{ padding: "7px 35px" }}
            >
              <Col span={16} className="flex items-center gap-2">
                <Avatar
                  src={userData?.image ?? dummyAvatar}
                  size={50}
                  style={{ border: "1px solid #dce0e6" }}
                />
                <div>
                  <p className="text-primary text-[14px] xs:text-[13px] font-semibold leading-6">
                    {"Team Leader"}
                  </p>
                  <p className="text-[16px] xs:text-[12px] leading-6 ">
                    {userData?.name}
                  </p>
                </div>
              </Col>
              <Col className="flex justify-end" span={8}>
                <Dropdown
                  menu={{ items }}
                  placement="bottomRight"
                  arrow
                  trigger={["click"]}
                >
                  <Avatar
                    src={admindetails?.image ?? dummyAvatar}
                    size={50}
                    style={{ border: "1px solid #dce0e6", cursor: "pointer" }}
                  />
                </Dropdown>
              </Col>
            </Row>
          </Header>
          <Content style={{ margin: "0 16px" }}>
            <Outlet />
          </Content>
        </Layout>
      </Layout>
      <Drawer
        placement="right"
        closable={false}
        onClose={() => setVisible(false)}
        open={visible}
      >
        <div className="flex-x center text-center profile-drawer">
          <Avatar size={100} src={Profile} className="mt-10" />
          <div className="mt-5 text-2xl font-medium">
            {admindetails?.name || "Website Admin"}
          </div>
          <div className="text-slate-500">
            {admindetails?.email || "admin@test.com"}
          </div>
          <Button danger className="mt-5 w-40 h-10" onClick={handleLogout}>
            Logout
          </Button>
        </div>
        <hr className="my30" style={{ background: "#E4E8F0" }} />
      </Drawer>
      <Modal
        open={editId}
        width={!isMobile() && 600}
        footer={false}
        cancelButtonProps={{ style: { display: "none" } }}
        className="form-modal-title"
        onCancel={() => {
          setEditId(null);
          form?.resetFields();
        }}
      >
        <RegistrationCard
          form={form}
          admindetails={admindetails}
          editId={editId}
          setEditId={setEditId}
          setRefresh={setRefresh}
        />
      </Modal>
    </>
  );
};

export default AppLayout;
