import { Navigate } from "react-router-dom";
import Error from "../page/error";
import UnAuthorize from "../page/unAuthorize";
import AppLayout from "../component/layout/AppLayout";
import Dashboard from "../page/app/dashboard";
import { appRoot } from "./constant/CONSTANTS";
// import ForgetPassword from "../page/user/Registration/forgetPass";
import TestLogin from "../page/user/login/test";
import RegistrationPage from "../page/user/login/registration-page";

export const ROUTES = {
  FORGOT_PASSWORD: "/forgot-password",
  DASHBOARD: "task",
  USER_MANAGEMENT: "user",
  USER_MANAGEMENT_DETAIL: "user/:id",
  SETTING: "setting",
  BRAND_MANAGEMENT: "brand",
  BRAND_MANAGEMENT_DETAIL: "brand/:id",
  TASK_MANAGEMENT: "task",
  TASK_MANAGEMENT_DETAIL: "task/:id",
  TASK_APPROVAL: "approval",
  BANNER: "banner",
  VERIFICATION: "verification",
  ROLE_MANAGEMENT: "role-management",
  ROLE_ACCESS_FORM: "access-form/:id",
};

const LOGIN_ROUTES = [
  {
    index: true,
    element: <TestLogin />,
  },
  {
    path: "registration",
    element: <RegistrationPage />,
  },
];

const ALL_ROUTES = (appProps) => {
  // const props = await appProps
  // root/task/taskID/userid
  // root/login
  // root/regis
  // root/notFound

  return [
    // ...LOGIN_ROUTES,
    {
      index: true,
      element: <TestLogin {...appProps} />,
    },
    {
      path: "registration",
      element: <RegistrationPage {...appProps} />,
    },
    // user = 1 task 87
    {
      path: `${appRoot}`,
      element: <AppLayout {...appProps} />,

      children: [
        {
          index: true,
          // element: <Navigate to={`${ROUTES.ROLE_MANAGEMENT}`} />,
          element: <Navigate to={`${appRoot}`} />,
        },
        {
          path: `:taskId`,
          children: [
            {
              path: ":userId", // Child route with a dynamic parameter
              children: [
                {
                  path: ":mobileNo",
                  children: [
                    {
                      index: true, // Default child route for /service/:id
                      element: <Dashboard {...appProps} />,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "/error",
      element: <Error />,
    },
    {
      path: "/unAuthorize",
      element: <UnAuthorize />,
    },
    {
      path: "*",
      element: <Navigate to="/error" />,
    },
  ];
};

export default ALL_ROUTES;
