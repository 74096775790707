import { Button, Card, Image, Modal } from "antd";
import React, { useState } from "react";
import {
  buttonChange,
  notesCard,
  statusCard,
} from "../../../../util/functions";
import { MdOutlineCalendarMonth } from "react-icons/md";
import loadIcon from "../../../../asset/image/loading.svg";
import TaskApply from "./task-apply";
import moment from "moment";
import { useParams } from "react-router-dom";
import { IoLocationOutline } from "react-icons/io5";

const RequestTask = ({
  admindetails,
  taskData,
  status = "",
  applyUserTaskHandler,
  setStatus,
  setRefresh,
}) => {
  const [show, setShow] = useState(false);
  const { taskId, userId } = useParams();
  const [openModel, setOpenModel] = useState(false);
  const expiryDate = moment(taskData?.userTasks[0]?.lastSubmissionDate);
  const currentDate = moment();
  const isModalVisible = () => {
    // if (!taskData?.userTasks?.length && taskData?.isRegionBased)
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        () => {
          setShow((pr) => !pr);
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED)
            setOpenModel((pre) => !pre);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
    // else setShow((pr) => !pr);
  };

  return (
    <>
      <Card bodyStyle={{ padding: 20 }}>
        <div className="flex justify-between items-center py-2">
          <div className="font-semibold text-lg text-primary flex items-center">
            <Image preview={false} src={loadIcon} />
            <p className="ml-2">{taskData?.completionTime} min</p>
          </div>
          <div>
            <p className="text-[#7D8995] font-semibold">ID : {taskId}</p>
          </div>
        </div>
        <div className="flex items-center justify-between py-2 pb-3">
          <div>
            <p className="text-[#7D8995] text-xs">Expiry Date</p>
            <div className="flex items-center mt-1">
              <MdOutlineCalendarMonth size={20} />
              <p className="ml-2">
                {moment(taskData?.expireDate).format("DD-MM-YYYY")}
              </p>
            </div>
          </div>
          {taskData?.userTasks &&
            taskData?.userTasks?.length > 0 &&
            statusCard(status)}
        </div>
        {taskData?.userTasks &&
          taskData?.userTasks?.length > 0 &&
          notesCard(status, taskData?.status, taskData?.userTasks[0])}
        {/* <div className="mt-16"> */}
        {!expiryDate.isBefore(currentDate) &&
          taskData?.status !== "Inactive" && (
            <>
              {taskData?.userTasks?.length && taskData?.trackUrl && status === "InProgress" ? (
                <Button
                  type="primary"
                  className="mt-16 w-full py-6 rounded-lg text-lg"
                  ghost
                  onClick={() => window.open(taskData?.trackUrl, "_blank")}
                >
                  Click here for task link
                </Button>
              ) : (
                ""
              )}
              {buttonChange({
                isModalVisible: () => isModalVisible(),
                status: taskData?.userTasks?.length < 0 ? "Apply" : status,
                applyUserTaskHandler: () => applyUserTaskHandler,
                show: show,
                setShow: setShow,
                isTrackUrl: taskData?.trackUrl
              })}
            </>
          )}
        {/* </div> */}

        <TaskApply
          admindetails={admindetails}
          setRefresh={setRefresh}
          isModalVisible={isModalVisible}
          applyUserTaskHandler={applyUserTaskHandler}
          show={show}
          status={taskData?.userTasks?.length < 0 ? "Apply" : status}
          setStatus={setStatus}
          taskData={taskData}
        />
      </Card>
      <Modal
        footer={
          <>
            <div className="flex gap-3 justify-start">
              <Button onClick={() => setOpenModel((pre) => !pre)}>Okay</Button>
            </div>
          </>
        }
        closable={false}
        title={
          <div className="flex  gap-2 items-center border-b-2 pb-2">
            <IoLocationOutline color="red" size={28} />
            <p className="text-2xl font-medium text-black">
              {"Permission Required"}
            </p>
          </div>
        }
        okButtonProps={{ style: { display: "none" } }}
        centered
        cancelText="Okay"
        open={openModel}
        onCancel={() => setOpenModel((pre) => !pre)}
      >
        <p className="text-secondary-700">
          {
            "Location access is required. Please enable it in your browser settings."
          }
        </p>
      </Modal>
    </>
  );
};

export default RequestTask;
